import { FirebaseApp, initializeApp, getApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore/lite'
import { ToastOptions, cssTransition, toast } from 'react-toastify'
import { useContext } from 'react'
import { GlobalStateContext } from '../context/GlobalStateProvider'
import { Page, Role } from '../constants'
import { postJson } from './api'
import theme from 'constants/theme'
import { OfficeHours } from '@components/shared/TerritoryInfo'

export const initFirebase = () => {
  const env = import.meta.env.DEV ? 'DEV' : 'PROD'
  const firebaseConfig = {
    apiKey: import.meta.env[`VITE_FIREBASE_${env}_API_KEY`],
    authDomain: import.meta.env[`VITE_FIREBASE_${env}_AUTH_DOMAIN`],
    projectId: import.meta.env[`VITE_FIREBASE_${env}_PROJECT_ID`],
    storageBucket: import.meta.env[`VITE_FIREBASE_${env}_STORAGE_BUCKET`],
    messagingSenderId: import.meta.env[
      `VITE_FIREBASE_${env}_MESSAGING_SENDER_ID`
    ],
    appId: import.meta.env[`VITE_FIREBASE_${env}_APP_ID`],
    measurementId: import.meta.env[`VITE_FIREBASE_${env}_MEASUREMENT_ID`],
  }
  return initializeApp(firebaseConfig)
}

export const getFirebaseApp = (): FirebaseApp => {
  return getApp()
}

export const fetchUserData = async (uid: string): Promise<User | null> => {
  const db = getFirestore(getFirebaseApp())
  const docRef = doc(db, `users/${uid}`)
  const snapshot = await getDoc(docRef)

  if (!snapshot.exists() || !snapshot.data()) {
    console.log(`No user found with the uid ${uid}`)
    return null
  }

  return snapshot.data() as User
}

interface Policy {
  value: boolean | number | string | null | OfficeHours
  description: string
}

export const persistTerritoryInfo = async (
  formState: Record<string, string | OfficeHours>
) => {
  const app = getFirebaseApp()
  const db = getFirestore(app)
  const getIdTokenResult = async () => {
    const auth = getAuth(app)
    const user = auth.currentUser
    if (!user) throw new Error('No user is currently signed in')
    return await user.getIdTokenResult(true)
  }

  const idTokenResult = await getIdTokenResult()

  const franchiseeId = idTokenResult.claims.franchiseeId as string | undefined

  if (!franchiseeId) {
    throw new Error('User does not have a franchiseeId claim')
  }

  const policyDescriptions: Record<string, string> = {
    companyName: 'The name of the franchisee',
    officeEmail: "The email address of the franchisee's office.",
    officePhone: "The phone number of the franchisee's office.",
    offerMakeupLessons:
      'Indicates whether the franchisee offers makeup lessons if a student misses a scheduled session.',
    makeupLessonsExpire:
      'Specifies if the makeup lessons provided are subject to expiration after a certain period.',
    expirationDays:
      'Defines the number of days after which a makeup lesson is no longer valid and cannot be redeemed.',
    advanceDays:
      'The number of days in advance a customer must schedule their makeup lesson.',
    noticeForLastMinuteAbsences:
      'Determines whether the company requires advance notice for last-minute student absences to qualify for makeup lessons.',
    lastMinuteMakeupsTime:
      'The amount of time required by the franchisee to arrange a last-minute makeup lesson.',
    lastMinuteMakeupsUnit:
      'The unit of time (hours or days) that applies to the last-minute makeup lessons time requirement.',
    noticeMinutesForLastMinuteAbsences:
      'The minimum number of minutes of advance notice required by the company for last-minute absences.',
    lessonsPerWeek:
      'The standard number of lessons a student is entitled to per week under their current plan.',
    midMonthProrate:
      'Whether the franchisee offers a prorated fee for either the first or second month for students who enroll in the middle of the month.',
    annualMembershipProrate:
      'Indicates if the franchisee prorates the annual membership fee based on the time of enrollment within the year.',
    chargeForFifthLesson:
      'Specifies whether the franchisee charges an additional fee for a fifth lesson in months that have five weeks.',
    multiClassDiscount:
      'Indicates whether the franchisee offers a discount for multiple classes booked per student',
    multiClassDiscountDescription:
      'The actual discount amount for the multi-class discount',
    multiStudentDiscount:
      'Indicates whether the franchisee offers a discount for multiple students booked at the same time',
    multiStudentDiscountDescription:
      'The actual discount amount for the multi-student discount',
    additionalPricingDetails: 'Additional pricing details for the franchisee',
    additionalPolicyDetails: 'Additional policy details for the franchisee',
    widgetTitle: 'The title of the widget',
  }

  const territoryInfoCategories = {
    contactInfo: {
      officeEmail: true,
      officePhone: true,
      companyName: true,
      officeHours: true,
    },
    pricing: {
      lessonsPerWeek: true,
      midMonthProrate: true,
      annualMembershipProrate: true,
      chargeForFifthLesson: true,
      multiClassDiscount: true,
      multiClassDiscountDescription: true,
      multiStudentDiscount: true,
      multiStudentDiscountDescription: true,
      additionalPricingDetails: true,
    },
    makeupsAndAbsences: {
      offerMakeupLessons: true,
      makeupLessonsExpire: true,
      expirationDays: true,
      advanceDays: true,
      noticeForLastMinuteAbsences: true,
      lastMinuteMakeupsTime: true,
      lastMinuteMakeupsUnit: true,
      noticeMinutesForLastMinuteAbsences: true,
      additionalPolicyDetails: true,
    },
    widget: {
      widgetTitle: true,
    },
  }

  const createPolicyObject = (key: string): Policy => ({
    value: formState[key],
    description: policyDescriptions[key] || '',
  })

  const transformAndFilterTerritoryInfo = (
    policyCategories: Record<string, Record<string, boolean>>
  ): Record<string, Record<string, Policy>> => {
    return Object.entries(policyCategories).reduce((acc, [category, obj]) => {
      acc[category] = Object.keys(obj).reduce((categoryAcc, key) => {
        const policyObject = createPolicyObject(key)
        if (policyObject.value !== null && policyObject.value !== undefined) {
          categoryAcc[key] = policyObject
        }
        return categoryAcc
      }, {} as Record<string, Policy>)
      return acc
    }, {} as Record<string, Record<string, Policy>>)
  }

  const territoryInfo = transformAndFilterTerritoryInfo(territoryInfoCategories)
  const ref = doc(db, 'franchisees', franchiseeId)
  await updateDoc(ref, {
    territoryInfo,
  })
}

export const jakToast = (msg: string, options: ToastOptions = {}) => {
  const fadeInOut = cssTransition({
    enter: 'fade-in-bottom',
    exit: 'fade-out-right',
  })
  toast(msg, {
    autoClose: 2000,
    style: { borderRadius: '10px', fontFamily: theme.typography.fontFamily },
    type: 'success',
    icon: false,
    progressStyle: { backgroundColor: theme.palette.primary.main },
    transition: fadeInOut,
    ...options,
  })
}

export const fetchIsEnabled = async (uid: string): Promise<boolean> => {
  if (!uid) {
    return false
  }
  const db = getFirestore(getFirebaseApp())
  const docRef = doc(db, 'users', uid)
  const snapshot = await getDoc(docRef)
  return snapshot.data()?.enabled ?? true
}

export const fetchUser = async () => {
  return getAuth(getFirebaseApp()).currentUser
}

export const logout = async () => {
  const auth = getAuth(getFirebaseApp())
  try {
    await auth.signOut()
    localStorage.removeItem('franchiseeId')
  } catch (error) {
    console.error('Error during force logout:', error)
  }
}

/* -------------------------- Hooks -------------------------- */

export const useRole = () => {
  const { state } = useContext(GlobalStateContext)
  const userRole = state.user?.role || 'user'

  const hasRole = (...roles: Role[]) => roles.includes(userRole)

  return { hasRole }
}

export const fetchHasPageAccess = async (page: Page): Promise<boolean> => {
  const res = await postJson('/dashboard/auth/single-page-access', { page })
  return res.ok
}
