import { createTheme } from '@mui/material/styles'
import { Sizes } from './Sizes'

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    disabled: true
  }
}

const theme = createTheme({
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: Sizes.Spacing.Px.Sixteen,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: Sizes.Spacing.Px.Eight,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: Sizes.Spacing.Px.Eight,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderRadius: Sizes.Spacing.Px.Eight,
        },
      },
    },
  },
  palette: {
    mode: 'light',
    primary: {
      100: '#d7f2f2',
      200: '#90dddd',
      300: '#49bcc1',
      400: '#22a8af',
      500: '#00838f',
      600: '#0b7070',
      700: '#0f5b5b',
      800: '#0c494c',
      900: '#083638',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ffb200',
      100: '#f9e8c7',
      200: '#ffe0ae',
      300: '#efd39c',
      400: '#efbd59',
      500: '#ffb200',
      600: '#bf8717',
      700: '#8c5e03',
      800: '#5e3e07',
      900: '#2b1c03',
      contrastText: '#fff',
    },
    //@ts-expect-error custom color
    disabled: {
      light: '#a9a9a9',
      main: '#a9a9a9',
      dark: '#a9a9a9',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
  },
})

export default theme
